const saveBlob = (name, type, blob) => {
  const file = new Blob([blob], { type });

  if (window.navigator.msSaveOrOpenBlob) {
    // нужно для работы в IE11
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
};

export default saveBlob;
