<template>
  <div class="page page_feedback">
    <esmp-table-wrapper
      class="feedback"
      title="Обратная связь"
      :is-column-settings-button-showed="false"
      :is-allow-full-page="false"
    >
      <template #header-actions>
        <esmp-button size="small" @click="$router.push('/admin/feedback/create')">
          Создать опросный лист
        </esmp-button>
      </template>
      <esmp-table
        :columns="columns"
        :rows="rows"
        :loading="loading"
        stripe
      >
        <template #cell-startDatetime="{ td }">
          {{ td | dateFormat('DD.MM.YYYY') }}
        </template>
        <template #cell-finishDatetime="{ td }">
          {{ td | dateFormat('DD.MM.YYYY') }}
        </template>

        <template #cell-result="{ tr }">
          <esmp-button
            size="small"
            @click="getResult(tr)"
          >
            Скачать
          </esmp-button>
        </template>
        <template #cell-actions="{ tr }">
          <div class="feedback__actions">
            <span class="feedback__actions-item" @click="editFeedbackItem(tr)">
              <esmp-icon name="doc-edit" />
            </span>
            <span class="feedback__actions-item" @click="showConfirmModal(tr)">
              <esmp-icon name="trash" />
            </span>
          </div>
        </template>
      </esmp-table>
    </esmp-table-wrapper>
    <esmp-modal
      v-model="confirmModalShowed"
      class="modal-confirm"
      @on-ok="deleteFeedbackItem"
    >
      <template #header>
        Удаление элемента
      </template>
      Вы уверены?
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import saveBlob from '@/components/service-form/helpers/saveBlob';

export default {
  name: 'Feedback',
  data() {
    return {
      loading: false,
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Заголовок',
          key: 'title',
        },
        {
          title: 'Начало публикации',
          key: 'startDatetime',
        },
        {
          title: 'Конец публикации',
          key: 'finishDatetime',
        },
        {
          title: 'Результаты',
          key: 'result',
        },
        {
          title: '',
          key: 'actions',
        },
      ],
      rows: [],
      confirmModalShowed: false,
      itemToDelete: null,
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    showConfirmModal(item) {
      this.itemToDelete = item;
      this.confirmModalShowed = true;
    },
    deleteFeedbackItem() {
      this.loading = true;
      this.$API.feedback.deleteQuestionary(this.itemToDelete.id).then(() => {
        this.rows = this.rows.filter((el) => el.id !== this.itemToDelete.id);
      }).finally(() => {
        this.$EsmpNotify.$show('Опросный лист удален', 'success');
        this.loading = false;
      });
    },
    editFeedbackItem(item) {
      this.$router.push(`/admin/feedback/edit/${item.id}`);
    },
    getQuestionary() {
      this.loading = true;
      this.$API.feedback.getQuestionary().then(({ data }) => {
        this.rows = data;
      }).finally(() => {
        this.loading = false;
      });
    },
    getResult(item) {
      this.setLoading({ key: 'page', value: true });
      this.$API.feedback.getQuestionaryResult(item.id).then(({ data }) => {
        saveBlob(`результаты_${item.title}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data);
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
  },
  created() {
    this.getQuestionary();
  },
};
</script>
<style lang="scss">
.feedback {
  &__actions {
    display: flex;
    flex-direction: row;
    &-item {
      cursor: pointer;
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0;
      }
    }
  }
}
.form-item {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
.feedback {
  .esmp-table__thead-tr {
    .esmp-table__thead-th:first-child {
      width: 42px;
    }
    .esmp-table__thead-th:last-child {
      width: 83px;
    }
  }
}
</style>
