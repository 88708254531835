<template>
  <div class="feedback-form">
    <validation-observer ref="form">
      <validation-provider
        class="feedback-form__row"
        rules="required"
        name="«Заголовок опросника»"
        v-slot="v"
        tag="div"
      >
        <esmp-input
          v-model="questionaryDto.title"
          label="Заголовок опросника"
          :error-message="v.errors.length ? v.errors[0] : ''"
        />
      </validation-provider>
      <h3 class="feedback-form__subtitle">
        Вопросы:
      </h3>
      <div class="feedback-form__questions">
        <div
          v-for="(question, index) in questionaryDto.questions"
          class="feedback-form__row"
          :key="`question_${question.id}`"
        >
          <div class="feedback-form__question-wrapper">
            <validation-provider
              rules="required"
              :name="`«Вопрос ${index + 1}»`"
              v-slot="v"
              tag="div"
              class="feedback-form__question-vp"
            >
              <esmp-input
                class="feedback-form__question"
                :label="`Вопрос ${index + 1}`"
                v-model="question.text"
                :options="{ type: 'textarea' }"
                clearable
                :error-message="v.errors.length ? v.errors[0] : ''"
              />
            </validation-provider>
            <span
              v-if="questionaryDto.questions.length > 1"
              class="feedback-form__question-action ml-10"
              @click="removeQuestion(question.id)"
            >
              <esmp-icon name="trash" />
            </span>
          </div>
          <esmp-checkbox
            v-model="question.required"
            class="feedback-form__question-checkbox"
          >
            Обязательно к заполнению?
          </esmp-checkbox>
        </div>
      </div>
      <esmp-button
        class="feedback-form__row"
        size="small"
        @click="addQuestion"
      >
        Добавить вопрос
      </esmp-button>
      <h3 class="feedback-form__subtitle">
        Настройки:
      </h3>
      <div class="feedback-form__row feedback-form__row--flex">
        <esmp-datepicker-adaptive
          :key="`question-start_${String(questionaryDto.finishDatetime)}`"
          v-model="questionaryDto.startDatetime"
          :disabled-dates="minStartDate"
          placeholder="Старт опроса"
          class="feedback-form__datepicker"
        />
        <esmp-datepicker-adaptive
          :key="`question-finish_${String(questionaryDto.startDatetime)}`"
          class="feedback-form__datepicker ml-20"
          v-model="questionaryDto.finishDatetime"
          :disabled-dates="minEndDate"
          placeholder="Дата окончания опроса"
        />
      </div>
      <div class="feedback-form__row">
        <div class="mb-5">
          При какой оценке просить комментарий?
        </div>
        <esmp-rate v-model="questionaryDto.ratingForComment" />
      </div>
      <div
        class="feedback-form__row feedback-form__row--flex feedback-form__row--min-height"
      >
        <esmp-switch
          v-model="questionaryDto.showRejectButton"
          shape="circular"
          label="Показывать кнопку - Отказаться"
        />
        <esmp-switch
          v-model="questionaryDto.showFillLaterButton"
          shape="circular"
          label="Показывать кнопку - Пройти позже"
          class="ml-20"
        />
        <div v-if="questionaryDto.showFillLaterButton" class="ml-20">
          <validation-provider
            rules="min_value:1"
            name="«Через сколько дней»"
            v-slot="v"
            tag="div"
          >
            <esmp-input
              v-model.number="questionaryDto.remainingDays"
              label="Через сколько дней?"
              :options="{ type: 'number', min: 1 }"
              class="feedback-form__later-days"
              :error-message="v.errors.length ? v.errors[0] : ''"
            />
          </validation-provider>
        </div>
      </div>
      <esmp-button @click="save">
        Сохранить
      </esmp-button>
    </validation-observer>
  </div>
</template>

<script>
import convertDate from '@/helpers/convertDate';

export default {
  name: 'FeedbackForm',
  props: {
    id: {
      type: Number,
      default: undefined,
    },
    title: {
      type: String,
      default: 'Опросный лист',
    },
    questions: {
      type: Array,
      default: () => [
        {
          id: 0,
          text: null,
          required: false,
        },
      ],
    },
    startDatetime: {
      type: [String, Object, Date],
      default: null,
    },
    finishDatetime: {
      type: [String, Object, Date],
      default: null,
    },
    remainingDays: {
      type: Number,
      default: 7,
    },
    ratingForComment: {
      type: Number,
      default: 3,
    },
    showRejectButton: {
      type: Boolean,
    },
    showFillLaterButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      questionaryDto: {
        title: this.title,
        questions: this.questions,
        startDatetime: this.startDatetime,
        finishDatetime: this.finishDatetime,
        remainingDays: this.remainingDays,
        ratingForComment: this.ratingForComment,
        showRejectButton: this.showRejectButton,
        showFillLaterButton: this.showFillLaterButton,
      },
    };
  },
  methods: {
    addQuestion() {
      const question = this.questionaryDto.questions.length
        ? {
          id:
            this.questionaryDto.questions[this.questionaryDto.questions.length - 1].id + 1,
          text: null,
          required: false,
        }
        : { id: 0, text: null, required: false };
      this.questionaryDto.questions = [
        ...this.questionaryDto.questions,
        question,
      ];
    },
    removeQuestion(id) {
      this.questionaryDto.questions = this.questionaryDto.questions.filter(
        (el) => el.id !== id,
      );
    },
    save() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          const dto = {
            ...this.questionaryDto,
            startDatetime: convertDate(this.questionaryDto.startDatetime),
            finishDatetime: convertDate(this.questionaryDto.finishDatetime),
          };
          this.$emit('on-save', dto);
        }
      });
    },
    minStartDate(date) {
      if (this.questionaryDto.finishDatetime) {
        return date.getTime() >= this.questionaryDto.finishDatetime;
      }
      return null;
    },
    minEndDate(date) {
      if (this.questionaryDto.startDatetime) {
        return date.getTime() <= this.questionaryDto.startDatetime;
      }
      return null;
    },
  },
  created() {
    if (this.id) this.questionaryDto.id = this.id;
  },
};
</script>
<style lang="scss">
.feedback-form {
  &__question {
    &-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }
    &-vp {
      flex: 1;
    }
    &-action {
      cursor: pointer;
    }
    &-checkbox {
      margin-top: 8px;
    }
  }
  &__datepicker {
    min-width: 230px;
  }
  &__row {
    margin: 0 0 20px 0;
    &--flex {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
    }
    &--min-height {
      min-height: 48px;
    }
  }
  &__subtitle {
    margin: 0 0 10px 0;
  }
  &__later-days {
    width: 300px;
    margin-top: -$base-gutter * 1.3;
  }
}
</style>
