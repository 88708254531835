<template>
  <div class="page page_feedback-create">
    <h2 class="page__title">
      Создание опросного листа
    </h2>
    <feedback-form @on-save="save" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FeedbackForm from '@/pages/admin/feedback/components/FeedbackForm.vue';

export default {
  name: 'Feedback',
  components: { FeedbackForm },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    save(dto) {
      this.setLoading({ key: 'page', value: true });
      Object.assign(dto, {
        questions: dto.questions.map((el) => ({ text: el.text, required: el.required })),
      });
      this.$API.feedback.setQuestionary(dto).then(({ data }) => {
        this.$EsmpNotify.$show('Опросный лист создан', 'success');
        this.$router.push(`/admin/feedback/edit/${data.id}`);
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
  },
};
</script>
<style lang="scss">
.page {
  &__title {
    margin: 0 0 20px 0;
  }
  &_feedback-create {
    padding: 32px;
    background: #fff;
    border-radius: 16px;
  }
}
</style>
