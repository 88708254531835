<template>
  <div class="page page_feedback-edit">
    <h2 class="page__title">
      Редактирование опросного листа №{{ $route.params.id }}
    </h2>
    <feedback-form
      v-if="form"
      :id="form.id"
      :title="form.title"
      :questions="form.questions"
      :start-datetime="form.startDatetime"
      :finish-datetime="form.finishDatetime"
      :remaining-days="form.remainingDays"
      :rating-for-comment="form.ratingForComment"
      :show-reject-button="form.showRejectButton"
      :show-fill-later-button="form.showFillLaterButton"
      @on-save="save"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FeedbackForm from '@/pages/admin/feedback/components/FeedbackForm.vue';

export default {
  name: 'Feedback',
  components: { FeedbackForm },
  data() {
    return {
      loading: false,
      form: null,
    };
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    getFeedbackForm() {
      this.setLoading({ key: 'page', value: true });
      this.$API.feedback.getQuestionaryById(this.$route.params.id).then(({ data }) => {
        this.form = {
          ...data,
          startDatetime: new Date(data.startDatetime),
          finishDatetime: new Date(data.finishDatetime),
        };
      })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    save(dto) {
      this.setLoading({ key: 'page', value: true });
      this.$API.feedback.setQuestionary(dto).then(() => {
        this.$EsmpNotify.$show('Опросный лист обновлен', 'success');
      }).finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
  },
  created() {
    this.getFeedbackForm();
  },
};
</script>
<style lang="scss">
.page {
  &__title {
    margin: 0 0 20px 0;
  }
  &_feedback-edit {
    padding: 32px;
    background: #fff;
    border-radius: 16px;
  }
}

</style>
